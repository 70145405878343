.account-information {
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(335px,1fr));
}

.account-information__data-pairs {
  padding: 10px 0;
}

.account-information__data-pairs .label-value-pairs div:nth-child(odd)::after {
  content: "";
}

.account-errors__message {
  width: 75%;
  padding-left: 24px;
}