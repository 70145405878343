.report-filters__toggle {
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.report-filters__toggle > span {
  text-transform: uppercase;
}

.report-filters__toggle .toggle {
  padding: 0 5px;
}