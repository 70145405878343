.SearchBar {
  width: 100%;
  padding: 0;
  display: flex;
  align-items: center;
}
.SearchBar .SearchBox {
  flex: 1;
}
.SearchBar .SearchBox .search {
  width: 50%;
  margin: 0;
}