.MembershipSummary__title {
  font-weight: 600;
  font-size: 15px;
  color: #93999f;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.MembershipSummary__members {
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(275px,1fr));
}
