.breadcrumbs {
  margin-bottom: 40px;
}

.breadcrumbs .inline-icon {
  transform: rotate(90deg);
}

.UserProfilePage .page-controls-primary {
  left: unset;
  right: unset;
  width: 100%;
  max-width: 1024px;
}

.page-title {
  display: flex;
  margin: 0;
}

.page-header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  min-height: 26px;
}

.header-child {
  flex-basis: 50%;
}

.admin-actions {
  margin: 0;
}

.page-body {
  margin-top: 30px;
}
