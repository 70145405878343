.upload-modal__actions {
  display: flex;
  align-items: center;
}

.upload-modal__actions > div {
  padding-right: 10px;
}

.upload-modal__actions .input-row {
  margin-bottom: 20px;
}

.upload-modal__actions.resource_type .ResourceTypeContainer .label-container {
  color: orange;
}
.upload-modal__actions .member_type .label-container {
  color: orange;
}

.upload-modal__helpful-hints {
  margin-bottom: 20px;
}
.upload-modal__helpful-hints > ul {
  list-style: inside;
}