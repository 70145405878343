.modal .modal-content {
  width: 469px;
}

.maximize .modal-content {
  width: 100%;
}

.modal-content .help-tooltip {
  width:38%;
}

.modal-content .help-tooltip-target {
  width:15px;
 }