.report-page {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 113px);
  overflow: auto;
}

.report__actions {
  display: flex;
  align-items: center;
}
.report__actions .ResourceTypeContainer,
.report__actions .input-row {
  padding-right: 10px;
}
.report__actions .input-row {
  margin-bottom: 20px;
}
.report__actions label.input-custom-select {
  padding: 0;
}
.report__actions-buttons {
  margin-left: auto;
}
