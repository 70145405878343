.profile-content {
  display: flex;
}

.profile-attributes {
  margin-bottom: 20px;
}

.data-item {
  display: table-row;
  line-height: 1em;
  word-break: break-word;
}

.data-item_label,
.data-item > span {
  display: table-cell;
}

.data-item_label {
  padding: 0 20px 10px 0;
  word-break: normal;
  text-transform: uppercase;
}

.admin-actions {
  margin-bottom: 30px;
}

.toggle-component {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.toggle-label {
  margin-right: 16px;
}

.groups-title {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 15px;
}
