.App {
  padding-top: 46px;
}

body.full-page .App {
  padding: 0;
}

body.full-page .App .page-component {
  background-color: inherit;
}
