.page-component {
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  top: 46px;
  right: 0;
  bottom: 0;
  left: 205px;
  background-color: #fff;
  padding: 25px 40px 40px;
}
