.dropdown-multi-selection__label {
  line-height: 1em;
  margin: 0 0 8px;
  text-align: left;
}

.dropdown-multi-selection__error {
  border: 1px solid rgb(222, 26, 0);
  border-radius: 2px;
}

.dropdown-multi-selection__error:hover + .input-message .input-message__text {
  display: block;
  position: relative;
}
