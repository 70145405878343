.calendar {
  line-height: 0;
  width: 290px;
}

.form-error.calendar .form-error-icon {
  display: none;
}

.form-error.calendar .input-container .input-calendar-value {
  border-width: 0px;
  border-top-color: rgb(222, 26, 0);
  border-right-color: rgb(222, 26, 0);
  border-bottom-color: rgb(222, 26, 0);
  border-left-color: rgb(222, 26, 0);
}

.calendar.input-calendar .input-container .input-calendar-value,
.form-error.calendar .input-container .input-calendar-value,
.form-error.calendar .form-error-message {
  width: 100%;
}

.form-error.calendar:hover .form-error-message {
  display: block;
}

.modal-body .ModelForm .input-width-medium.input-text,
.modal-body .ModelForm .input-width-medium.input-textselect,
.modal-body .ModelForm .input-width-medium.input-textselect .input-text.form-error,
.modal-body .ModelForm .input-width-medium.input-textselect .input-text.input-message,
.modal-body .ModelForm .input-width-medium.input-select {
  min-width: 100%;
}

.referenceField__edit-button {
  margin-left: 10px;
  margin-bottom: 6px;
}

.ModelForm .input-file-upload.image-upload .file-size {
  position: initial;
}

.ModelForm .input-file-upload .file-info .icon-file {
  font-size: 20px;
}