.breadcrumbs {
  margin-bottom: 40px;
}

.breadcrumbs .inline-icon {
  transform: rotate(90deg);
}

.EditModelPage .page-controls-primary {
  left: unset;
  right: unset;
  width: 100%;
  max-width: 1024px;
}

.page-title {
  display: flex;
  margin: 0;
}

.page-header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  min-height: 26px;
}

.header-child {
  flex-basis: 50%;
}

.admin-actions {
  margin: 0;
}

.page-body {
  margin-top: 30px;
}

.EditModelPage .groups-content.no-groups {
  margin-left: 25px;
}

.summaryAtt {
  text-transform:  uppercase;
}

.EditModelPage .profileContent .label .label-text .help-tooltip {
  margin-left: none;
}

.EditModelPage .profileContent .help-tooltip {
  width:38%;
}
.EditModelPage .profileContent .help-tooltip-target {
 width:15px;
}