.reporting-filters__container {
  min-height: 87px;
  max-height: 87px;
}

.report__filters-entries {
  margin-top: 22px;
}

.report__filters-entries label {
  margin-bottom: 0;
  padding: 0;
}

.report__filters-entries div {
  margin-bottom: 0;
}

/* 
  The ReportFilters component does not provide a reset button.
  Adding styling to make the buttons be together.
*/
.reporting-filters__container .reporting-filters--inputs:last-of-type {
  margin-left: auto;
}
.reporting-filters__container .reporting-filters--inputs:last-of-type .button.link {
  padding: 0 25px;
  height: 36px;
  line-height: 36px;
  font-size: 15px;
  vertical-align: middle;
}
.reporting-filters__container .reporting-filters--button-margin {
  margin-left: 0;
}
