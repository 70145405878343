body.full-page {
  background-color: #575f67;
}

body.auth-page {
  background-color: #3d454d;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
