.report {
  padding: 20px;
  border: 1px dashed rgb(127, 135, 141);
}
.report .report-table {
  max-width: 98%;
}
.report .report-table.fixed-head .dd-table-container {
  height: calc(100vh - 509px);
  display: block;
}

.report .report-table.fixed-head .thead {
  display: table;
  width: 100%;
}
.dd-column.td {
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
}

.report-table.infinite-scroll-container .dd-table-container .batch {
  width: 100%;
}

.report__message {
  min-height: calc(100vh - 419px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.report__message .inline-message {
  font-size: 1.25rem;   
}

.report__results-count {
  margin-bottom: 20px;
}

/* Set height for smaller screens */
@media screen and (max-height: 753px) {
  .report .report-table.fixed-head .dd-table-container {
    min-height: 169px;
  }
}
@media screen and (max-height: 686px) {
  .report {
    height: 100%;
  }
}

/* Adding sorting indicator to button*/ 
.report-table .dd-table-container .table .th button.button.ascending.inline.link:after,
.report-table .dd-table-container .table .th button.button.descending.inline.link:after {
  -webkit-transition: all .2s;
  transition: all .2s;
  font-size: 11px;
  font-family: iconfont;
  content: "\31";
  position: absolute;
  margin-left: 5px;
}

.report-table .dd-table-container .table .th button.button.descending.inline.link:after {
  transform: rotate(-180deg);
}
