.user-select {
  position: relative;
  margin: 0 0 25px;
}

.user-select__columns {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
}

.user-select__column {
  box-sizing: border-box;
  width: calc(50% - 15px);
}

.column-header {
  margin-bottom: 15px;
}

.column-title {
  color: #3d454d;
  text-transform: uppercase;
  margin-right: 10px;
}

.page-body_divider {
  margin-bottom: 50px;
}
