.centered-info-box {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  align-items: center;
  padding: 40px 40px;
  background-color: #fff;
  margin: 80px auto 0 auto;
  box-sizing: border-box;
}

.centered-info-box .inline-message {
  margin-top: 0;
}

.SubmitBtn {
  margin: 0;
  width: 100%;
}

.spinner-message {
  margin-top: 20px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .centered-info-box {
    display: flex;
    flex-direction: column;
    width: auto;
    max-width: 300px;
    align-items: center;
    padding: 40px 40px;
    background-color: #fff;
    margin: 80px auto 0 auto;
  }
}
