.SearchBar .ResourceTypeContainer {
  margin-right: 20px;
}

.ResourceTypeContainer .input-custom-select {
  padding: 0;
}

.SearchBar .ResourceTypeContainer .input-custom-select {
  margin: 0;
}