.search-controls {
  width: 100%;
}

.search-controls__item {
  padding-right: 20px;
}

.search-controls__filters {
  margin-top: 20px;
}

.sort-dropdown {
  white-space: nowrap;
}

.reset-password-modal .modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.new-password {
  margin-top: 20px;
}

.search__no-results {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 143px;
  right: 0;
  left: 0;
  bottom: 0;
}

.search__no-results .inline-message {
  font-size: 1.25rem;   
}

.search-page-header {
  display: flex;
  flex-direction: row;
}

.search-page-header .button {
  margin: 0;
}